import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'common/components/UI/ContainerTwo';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import List from 'common/components/List';
import { GatsbyImage } from "gatsby-plugin-image";


import SectionWrapper, {
  Section,
  Content,
  Illustration,
  ListGroup,
} from './workHard.style';
// import data from 'common/data/AgencyModern';
import illustration from 'common/assets/image/portfolio/me_and_don_beach.jpg';
import nng from 'common/assets/image/portfolio/200nng_ilija_bojchovikj.png';
import cxa from 'common/assets/image/portfolio/cxa_ilija_bojchovikj.png';
import uxqcc from 'common/assets/image/portfolio/uxqcc_2_ilija_bojchovikj.png';

const WorkHard = () => {
  const Data = useStaticQuery(graphql`{
  Tick: file(relativePath: {eq: "image/portfolio/crown2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100,
        width: 25,
        layout: CONSTRAINED )
    }
  }

  Don: file(relativePath: {eq: "image/portfolio/me_and_don_beach.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100,
        layout: FULL_WIDTH )
    }
  }


  portfolioJson {
    workHardList {
      id
      title
    }
    SOCIAL_PROFILES {
      icon
      url
      text
    }
  }
}
`);

  return (
    <SectionWrapper>
      <Container className="container_sm">
        <Section>
          <Content>
            <Heading
              as="h2"
              content=" Let's get serious. Yeah, it's me and Don on the beach, talking UX."
            />
            <Text content="I'm UX Libertarian, nudging users to take the right direction. 10 years in the game, translating robot to human language and vice-versa. UX Strategy? Disney, Coca-Cola, Canon, Southwest Airlines, fintech, healthcare, manufacturing, television, airline, software development conglomerates…you name it, they have it, probably with my help." />
            <ListGroup>
              {Data.portfolioJson.workHardList.map((item) => (
                <List
                  className="list-item"
                  key={item.id}
                  text={item.title}
                  icon={
                    <GatsbyImage
                    image={
                        (Data.Tick !== null) | undefined
                          ? Data.Tick.childImageSharp.gatsbyImageData
                          : {}
                      }
                      src={Data.Tick.childImageSharp.gatsbyImageData.src}
                      objectFit="contain"
                      alt="crown"
                    />
                  }
                />
              ))}
            </ListGroup>
            <Heading
              as="h3"
              content="Seals of approval (only the best)"
              mt={50}
            />
             <Image className="seals ml-collapse" src={nng} alt="Norman Nielsen Group" title="Norman Nielsen Group" />
             <Image className="seals" src={cxa} alt="Human Factors International" title="Human Factors International" />
             <Image className="seals" src={uxqcc} alt="User Experience Quality Certification Center" title="User Experience Quality Certification Center" />
          </Content>
          <Illustration>
          <GatsbyImage
                    image={
                        (Data.Don !== null) | undefined
                          ? Data.Don.childImageSharp.gatsbyImageData
                          : {}
                      }
                      src={Data.Don.childImageSharp.gatsbyImageData.src}
                      objectFit="contain"
                      alt="crown"
                    />
            {/* <Image src={illustration} alt="IlijaAndDonNorman" /> */}
          </Illustration>
        </Section>
      </Container>
    </SectionWrapper>
  );
};

export default WorkHard;
