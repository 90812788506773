import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { portfolioTheme } from 'common/theme/portfolio';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/Portfolio/portfolio.style';

import Navbar from 'containers/Portfolio/Navbar';
import ProcessSection from 'containers/Portfolio/Process';
import AboutContactSection from 'containers/Portfolio/AboutContact';
import AboutContactSectionFooter from 'containers/Portfolio/AboutContactFooter';
import Footer from 'containers/Portfolio/Footer';
import SEO from 'components/seo';
import WorkHard from 'containers/Portfolio/WorkHard';
import LatestNews from 'containers/Portfolio/LatestNews';
import Helmet from 'react-helmet';

export default () => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Helmet>
        <meta property="og:image" content={'/images/og-image.png'} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Fragment>
        <SEO title="It's (not)Home | IB" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>

          <WorkHard />
          <ProcessSection />
          {/* <AboutBanner /> */}
          <AboutContactSection />
          <LatestNews />
          <AboutContactSectionFooter />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
