import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';

import { ButtonWrapper } from '../../Portfolio/portfolio.style';
import { AboutActiveStatus } from './contact.style';
import illustration from 'common/assets/image/portfolio/packt-logo.png';
import uxblog from 'common/assets/image/portfolio/uxblog-logo.png';

const AboutContactSectionFooter = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  replyWrapper,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} className="ftl_t" content="Few things left" />
          <Text
            {...secDescription} className="ftl_d"
            content="I'm main reviewer of two ultra-interesting books for PacktPublishing - the biggest library for the IT Crowd. Be sure you check them out."
          />
        </Box>
        
        <Box {...replyWrapper}>
         
          <AboutActiveStatus>
          <Image src={illustration} className="packt-logo" alt="Pactk_Publishing" />
            <ButtonWrapper className="buttonWrapper">
         
          <Button
                onClick={()=>{window.open("https://www.packtpub.com/product/mastering-jquery/9781783985463")}}
                title="📕 Mastering jQuery"
                className="portfolio_button blue"
              />
              <Button
                onClick={()=>{window.open("https://www.packtpub.com/product/mastering-jquery-ui/9781783286652")}}
                title="📕 Mastering jQueryUI"
                className="portfolio_button green"
              />
              </ButtonWrapper>
          </AboutActiveStatus>
        </Box>

        <Box {...secTitleWrapper} mt={50}>
          <Text
            {...secDescription}
            content=".. and I occasionally write for the UXBlog"
          />
        </Box>
        
        <Box {...replyWrapper}>
         
          <AboutActiveStatus>
          <Image src={uxblog} className="packt-logo" alt="TheUXBlog" />
            <ButtonWrapper className="buttonWrapper">
         
          <Button
                onClick={()=>{window.open("https://medium.theuxblog.com/the-future-of-web-cards-twitter-cards-google-cards-and-everybody-on-cards-aa3868caef5d")}}
                title="✍️ The future of web"
                className="portfolio_button medium_btn"
              />
              </ButtonWrapper>
          </AboutActiveStatus>
        </Box>
        {/* <Box {...buttonWrapper}>
          <ButtonWrapper>
            <Button
              title="hello@ilija.guru"
              className="portfolio_button"
              {...buttonStyle}
            />
          </ButtonWrapper>
        </Box> */}
      </Container>
    </Box>
  );
};

AboutContactSectionFooter.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  replyWrapper: PropTypes.object,
  replyTime: PropTypes.object,
  buttonStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
};

AboutContactSectionFooter.defaultProps = {
  sectionWrapper: {
    pt: ['70px', '80px', '100px', '100px', '100px'],
    pb: ['70px', '80px', '100px', '100px', '100px'],
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: '30px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#111',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#111',
    lineHeight: '1.5',
    textAlign: 'center',
    width: '100%',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
    mb: '0',
  },
  replyWrapper: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyTime: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#111',
    mb: 0,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '50px',
  },
};

export default AboutContactSectionFooter;
