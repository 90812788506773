import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';

import { ButtonWrapper } from '../../Portfolio/portfolio.style';
import { AboutActiveStatus } from './contact.style';

const AboutContactSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  replyWrapper,
  replyTime,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} className="braniac_h" content="Where am I, and what i do?" />
          <Text
            {...secDescription} className="braniac_d"
            content="At this moment im located in 🌍 Europe and i do MIND BLOWING UX STUFF. When it comes to work i don't mess around. Having 100% positive reviews is really tough (especially when it comes managing stakeholders). Having discretion is also tough. Thats why I don't make that classic way of presenting my style and my processes. If you want to know more, shoot me a message wherever you want, or simply make a few clicks and book 15 min. video call with me."
          />
        </Box>
        <Box {...replyWrapper}>
         
          <AboutActiveStatus>
            <ButtonWrapper className="buttonWrapper">
            <Heading
            as="h4"
            content="Find me here →"
            {...replyTime} mr={20} mt={4}
          />
          <Button
                onClick={()=>{window.open("https://www.linkedin.com/in/ilijab/")}}
                title="Linkedin"
                className="portfolio_button blue"
              />
              <Button
                onClick={()=>{window.open("https://www.upwork.com/fl/ilija")}}
                title="Upwork"
                className="portfolio_button green"
              />
              <Button
                onClick={()=>{window.open("https://calendly.com/o_0/15mins")}}
                title="Schedule a meeting"
                className="portfolio_button teal"
              />
              </ButtonWrapper>
          </AboutActiveStatus>
        </Box>
        {/* <Box {...buttonWrapper}>
          <ButtonWrapper>
            <Button
              title="hello@ilija.guru"
              className="portfolio_button"
              {...buttonStyle}
            />
          </ButtonWrapper>
        </Box> */}
      </Container>
    </Box>
  );
};

AboutContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  replyWrapper: PropTypes.object,
  replyTime: PropTypes.object,
  buttonStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
};

AboutContactSection.defaultProps = {
  sectionWrapper: {
    pt: ['70px', '80px', '100px', '100px', '100px'],
    pb: ['70px', '80px', '100px', '100px', '100px'],
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: '30px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#111',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#111',
    lineHeight: '1.5',
    textAlign: 'center',
    width: '100%',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
    mb: '0',
  },
  replyWrapper: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyTime: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#111',
    mb: 0,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '50px',
  },
};

export default AboutContactSection;
