import styled from 'styled-components';

export const AboutActiveStatus = styled.div`
  width: 100%;
  position: relative;
  // &:before {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   width: 20px;
  //   height: 20px;
  //   border-radius: 50%;
  //   background-color: #00ff24;
  //   border: 3px solid #fff;
  //   right: -2px;
  //   bottom: -2px;
  // }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .packt-logo {
    display: flex;
    width: 100px;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-bottom: 30px;

  }

  .medium_btn {
    color: #111;
    background: #f9f9f9; 
  }


  .buttonWrapper {
    @media (max-width: 990px) {
      display:block;
    }
  }

  .portfolio_button {
    height: 50px;
    @media (max-width: 990px) {
      display:block;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    @media only screen and (max-width: 480px) {
      width: 100% !important;
    }
  }

h4 {
  @media (max-width: 990px) {
    display:flex;
    justify-content: center;
}
}

.blue {
  margin-right: 20px;
  @media (max-width: 990px) {
    margin: 0 auto;
    margin-bottom: 35px;
    margin-top: 35px;
   }
}
.blue, .green {
  background: #e68523;
}
.green {
    margin-right: 20px;
    @media (max-width: 990px) {
      margin: 0 auto;
      margin-top: 35px;
      margin-bottom: 35px;
     }
}

.orange:before {
  background: #e68523 !important;
}

.teal {}

.teal:before {
  background: #e68523 !important;
}



`;
